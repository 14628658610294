import { useState } from 'react';
import {
    Card,
    CardHeader,
    Heading,
    CardBody,
    Stack,
    StackDivider,
    Box,
    Text,
    Badge,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Select,
    NumberInput,
    NumberInputField,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderTrack,
    SliderThumb,
    Tooltip,
    Alert,
    AlertIcon
} from '@chakra-ui/react';

import { flatData } from './Data';

export function FlatSelectionCard({ setSelectedFlat, onRoomSelected }) {
    const [levelOptions, setLevelOption] = useState([{ value: 0, label: "EG" }, { value: 1, label: "1. OG" }, { value: 2, label: "2. OG" }, { value: 3, label: "3. OG" }]);
    const [flatOptions, setFlatOptions] = useState([]);
    const [roomOptions, setRoomOptions] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState();

    function buildingSelected(e) {
        var newOptions;
        if (e.target.value === "south") {
            newOptions = [{ value: 0, label: "EG" }, { value: 1, label: "1. OG" }, { value: 2, label: "2. OG" }];
        } else {
            newOptions = [{ value: 0, label: "EG" }, { value: 1, label: "1. OG" }, { value: 2, label: "2. OG" }, { value: 3, label: "3. OG" }];
        }
        setLevelOption(newOptions);
        setSelectedBuilding(e.target.value);
        setSelectedFlat(null);
        onRoomSelected(null);
    };

    function levelSelected(e) {
        var newData = flatData[selectedBuilding][e.target.value];
        if (newData) {
            setFlatOptions(flatData[selectedBuilding][e.target.value]);
        }
        setSelectedFlat(null);
        onRoomSelected(null);
    }

    function flatSelected(e) {
        var index = e.target.value;
        var flat = flatOptions[index];
        if (flat.wgRoomList) {
            setRoomOptions(flat.wgRoomList);
        }
        setSelectedFlat(flatOptions[index]);
        onRoomSelected(null);
    }
    function roomSelected(e) {
        onRoomSelected(roomOptions[e.target.value]);
    }

    return <Card>
        <CardHeader>
            <Heading size='md'>Wohnungsauswahl</Heading>
        </CardHeader>
        <CardBody>
            <Stack spacing={4} >
                <Select placeholder='Gebäude auswählen' onChange={buildingSelected}>
                    <option value='north'>Nordbau</option>
                    <option value='south'>Südbau</option>
                </Select>
                <Select placeholder='Etage auswählen' onChange={levelSelected}>
                    {levelOptions.map(({ value, label }, index) => <option value={value} >{label}</option>)}
                </Select>
                <Select placeholder='Wohnung auswählen' onChange={flatSelected}>
                    {flatOptions.map((flat, index) => <option value={index} >{flat.print()}</option>)}
                </Select>
                {roomOptions.length > 0 ? (<Select placeholder='Zimmer auswählen' onChange={roomSelected}>
                    {roomOptions.map((room, index) => <option value={index} >{room.print()} ({room.sizePrivate.toFixed(2)} m²)</option>)}
                </Select>) : null}
            </Stack>
        </CardBody>
    </Card >
}

export function FlatDetailsCard({ flat, room, flatRent }) {
    var body = <CardBody>
        Bitte auswählen
    </CardBody>;

    var badges = "";

    if (flat && (flat.wgRoomList.length === 0 || room) && flatRent) {
        var freeRent = flat.freeRent;
        var wbsRent = flat.wbsRent;
        var minRent = flat.minRent;
        if (room) {
            freeRent = room.freeRent;
            wbsRent = room.wbsRent;
            minRent = room.minRent;
        }

        var sizeSharedBox = null;

        if (flat.wgRoomList.length > 0) {
            var totalSizeToPay = room.sizePrivate + room.sizeShared;
            sizeSharedBox = [
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Anteil Interne Gemeinschaftsfläche (WG und Cluster)
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {room.sizeShared.toFixed(2)} m²
                    </Text>
                </Box >,
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Zahlfläche
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {room.sizePrivate.toFixed(2)} m² + {room.sizeShared.toFixed(2)} m² (WG/Cluster-Fläche)
                    </Text>
                    <Text pt={3} size='s' fontWeight='bold'>Gesamt: {totalSizeToPay.toFixed(2)} m² </Text>
                </Box>
            ];

        }

        body = <CardBody>

            <Stack divider={<StackDivider />} spacing='4'>

                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Bezeichnung
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {flat.name} {room ? "Zimmer: " + room.print() : null}
                    </Text>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Zimmeranzahl {room ? "" : "und Gesamtfläche"}
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {flat.numRooms} Zimmer {room ? "" : " " + flat.sizePrivate.toFixed(2) + "m²"}
                    </Text>
                </Box>
                {sizeSharedBox}
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Standardmiete WBS
                    </Heading>
                    <Text fontSize="xs">Die Standardmiete ergibt sich aus dem WBS Satz für die private Fläche, den Betriebskosten, der Küchen-Nutzungspauschale, den Abschlägen für Strom und Wasser sowie einem Gemeinschaftsflächenbeitrag und wird WBS-Wohnungen/Zimmern automatisch zugewiesen, sollte die entsprechende Partei kein Gebot abgeben. <b>Mit der Standardmiete wäre das gesamte Haus finanziert, wenn wir keine Bieterunden durchführen würden.</b></Text>
                    <Text pt='2' fontSize='xl' fontWeight='bold'>
                        {(wbsRent).toFixed(2)} €
                    </Text>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Standardmiete freifinanziert {flat.isWbs ? "(zum Vergleich)" : ""}
                    </Heading>
                    <Text fontSize="xs">Die Standardmiete freifinanziert, wird analog zur Standardmiete WBS berechnet, wobei der Flächenpreis für freifinanzierte Wohnungen genommen wird. Sie wird frei finanzierten Wohnungen/Zimmern automatisch zugewiesen, sollte die entsprechende Partei kein Gebot abgeben. <b>Mit der Standardmiete wäre das gesamte Haus finanziert, wenn wir keine Bieterunden durchführen würden.</b></Text>
                    <Text pt='2' fontSize='xl' fontWeight='bold'>
                        {(freeRent).toFixed(2)} €
                    </Text>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Mindestmiete
                    </Heading>
                    <Text fontSize="xs">Die Mindestmiete stellt die untere Grenze dar, die für diese Wohnung geboten werden darf und berechnet sich aus der Standardmiete WBS - 15 %
                    </Text>
                    <Text pt='2' fontSize='xl' fontWeight='bold'>
                        {(minRent).toFixed(2)} €
                    </Text>
                </Box>
            </Stack>
        </CardBody>;

        if (flat.isWbs) {
            badges = <Badge colorScheme='blue'>
                mit WBS
            </Badge>;
        } else {
            badges = <Badge colorScheme='yellow'>
                ohne WBS
            </Badge>;
        }
    }

    return (
        <Card>

            <CardHeader>
                {badges}
                <Heading size='md'>Wohnungsdetails</Heading>
            </CardHeader>
            {body}
        </Card>
    );
}

export function SelfEvaluationCard({ rent, setRent }) {

    const [sliderValue, setSliderValue] = useState(30)
    const [showTooltip, setShowTooltip] = useState(false)

    const [income, setIncome] = useState(0)

    const formatEuro = (val) => val + ' €';
    const parseEuro = (val) => val.replace(/^€/, '')


    function onIncomeChange(valueString) {
        setIncome(parseEuro(valueString));
        setRent(valueString * sliderValue / 100);
    }

    function onSliderChange(value) {
        setSliderValue(value);
        setRent(income * value / 100);
    }

    return <Card>
        <CardBody maxW={500}>
            <Stack spacing={3}>
                <Text>Wie hoch ist euer/dein Haushaltsnetto? Beachtet dabei alle regelmäßigen Einkünfte wie Gehalt, Rente, Mieteinkünfte oder Kapitalerträge </Text>
                <NumberInput
                    onChange={onIncomeChange}
                    value={formatEuro(income)}
                >
                    <NumberInputField />
                </NumberInput>

                <Text>Wie viel Prozent eures/deines Nettoeinkommens kannst du für Miete aufbringen?</Text>
                <Slider
                    id='slider'
                    defaultValue={30}
                    min={10}
                    max={50}
                    mb={5}
                    colorScheme='teal'
                    onChange={onSliderChange}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <SliderMark value={10} mt='1' ml='-2.5' fontSize='sm'>
                        10%
                    </SliderMark>
                    <SliderMark value={20} mt='1' ml='-2.5' fontSize='sm'>
                        20%
                    </SliderMark>
                    <SliderMark value={30} mt='1' ml='-2.5' fontSize='sm'>
                        30%
                    </SliderMark>
                    <SliderMark value={40} mt='1' ml='-2.5' fontSize='sm'>
                        40%
                    </SliderMark>
                    <SliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>
                        50%
                    </SliderMark>
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <Tooltip
                        hasArrow
                        bg='teal.500'
                        color='white'
                        placement='top'
                        isOpen={showTooltip}
                        label={`${sliderValue}%`}
                    >
                        <SliderThumb />
                    </Tooltip>


                </Slider>
            </Stack>
        </CardBody>
    </Card>
}

export function ResultsCard({ rent, flat, room, flatRent }) {
    var body = <CardBody></CardBody>
    if (rent && flat && (room || flat.wgRoomList.length === 0) && flatRent) {
        var _wbsRent = flat.wbsRent;
        var _freeRent = flat.freeRent;
        var _minRent = flat.minRent;
        if (room) {
            _wbsRent = room.wbsRent;
            _freeRent = room.freeRent;
            _minRent = room.minRent;
        }
        var totalSize = flat.calcTotalPayedArea(room)
        var relativeRent = (rent) / totalSize;

        var status = "error", text = "Dieses Gebot läge unter der Mindestmiete und ist nicht zulässig";

        if (rent >= _minRent) {
            status = 'info';
            text = "Über der Mindestmiete. "
        }
        if (rent >= _wbsRent) {
            status = 'success';
            text = 'Über der Standardmiete WBS 🎉'
        }
        if (rent >= _freeRent) {
            status = 'success';
            text = 'Über der Standardmiete freifinanziert 🎉🎉🎉'
        }

        body = <CardBody>
            <Stack>
                <Text fontWeight="bold">
                    Das ergäbe eine Miete von:
                </Text>
                <StatGroup>
                    <Stat>
                        <StatLabel>Warmmiete</StatLabel>
                        <StatNumber>{rent} €</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel>Pro m²</StatLabel>
                        <StatNumber>{relativeRent.toFixed(2)} € </StatNumber>
                    </Stat>
                </StatGroup>
                <Alert status={status}>
                    <AlertIcon />
                    {text}
                </Alert>
            </Stack>
        </CardBody>;
    }

    return <Card>
        {body}
    </Card>
}