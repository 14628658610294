import { React, useState, useEffect } from 'react';

import {
  ChakraProvider,
  extendTheme,
  Heading,
  Center,
  SimpleGrid,
  Box,
  Text,
  Stack,
  useColorModeValue,
  OrderedList,
  ListItem,
  Alert,

} from '@chakra-ui/react';

import { FlatSelectionCard, FlatDetailsCard, SelfEvaluationCard, ResultsCard } from './Solimieten';

import { Nav } from './Nav';
import { minRent, maxRent, minRentFactor } from './Data';
function App() {

  const [selectedFlat, setSelectedFlat] = useState();
  const [selectedRoom, setSelectedRoom] = useState();


  const [rent, setRent] = useState(0);
  const [flatRent, setFlatRent] = useState();

  const theme = extendTheme({
    "colors": {
      "gray": {
        "50": "#F3FEE7",
        "100": "#DEFCBB",
        "200": "#C8FA8F",
        "300": "#B3F862",
        "400": "#9DF636",
        "500": "#88F50A",
        "600": "#6DC408",
        "700": "#529306",
        "800": "#366204",
        "900": "#1B3102"
      }
    }
  });

  useEffect(() => {
    setFlatRent(null);
    if (selectedFlat && (selectedFlat.wgRoomList.length === 0 || selectedRoom)) {
      console.log("Recalculating Rent...");
      var commonAreaShare = 60;
      var kitchenCost = 50;
      var sizePrivate = selectedFlat.sizePrivate;
      var internalAreaShare = 0;

      if (selectedFlat.wgRoomList.length > 0) {
        internalAreaShare = selectedFlat.calcInternalAreaShare(selectedRoom);
        sizePrivate = selectedRoom.size;
        kitchenCost = kitchenCost / selectedFlat.wgRoomList.length;
      }

      var newRent = {
        minCovering: (sizePrivate + internalAreaShare) * minRent + kitchenCost + commonAreaShare,
        maxCovering: (sizePrivate + internalAreaShare) * maxRent + kitchenCost + commonAreaShare
      }
      newRent.min = newRent.minCovering * minRentFactor;

      setFlatRent(newRent);
    }
  }, [selectedFlat, selectedRoom])


  return (

    <ChakraProvider theme={theme}>
      <Nav></Nav>
      <Center p={5}>
        <Stack maxWidth={1200}>
          <Box p={6} borderRadius="lg" bg={useColorModeValue('gray.100', 'gray.200')}>
            <Text>
              <Heading>Allmende Solimieten-Helferlein</Heading>

              Im Rahmen des Konzept für solidarische Mieten, kann jede Partei in der Allmende ihre Miethöhe selbst bestimmen.
              Dieses Tool soll als Orientierungshilfe bei der Selbsteinschätzung dienen.
            </Text>
            <Text pt={3}>Dies geschieht in folgenden Schritten:
            </Text>
            <OrderedList pt={3}>
              <ListItem>Wähle deine Wohnung aus</ListItem>
              <ListItem>Bestimme das monatliche Haushaltseinkommen</ListItem>
              <ListItem>Wähle den Prozentsatz des Nettoeinkommens, den du zu zahlen bereit bist aus</ListItem>
              <ListItem>Nun siehst du den daraus resultierenden Mietpreis absolut und auf den Quadratmeter gerechnet.</ListItem>
            </OrderedList>
          </Box>
          <Alert status='warning'><Text> <b>ACHTUNG:</b> Durch die vielen Änderungen in den letzten Tagen wurden dieses Tool zuletzt mit heißer Nadel gestrickt. Bitte verzeiht, wenn etwas nicht funktioniert. Wenn euch etwas komisch vorkommt, meldet euch bei Niklas. Die Zahlen sind alle ohne Gewähr. <b>Grundlage für's Bieteverfahren sind die Zahlen, die wir rumgeschickt haben.</b></Text></Alert>
          <SimpleGrid pt={5} columns={{ base: 1, md: 2, lg: 2 }} spacing={4}>

            <Heading>Wohnung</Heading>
            <Box></Box>

            <FlatSelectionCard setSelectedFlat={setSelectedFlat} onRoomSelected={setSelectedRoom} />
            <FlatDetailsCard flat={selectedFlat} room={selectedRoom} flatRent={flatRent} />

            <Heading>Selbsteinschätzung</Heading>
            <Box></Box>

            <SelfEvaluationCard rent={rent} setRent={setRent} />
            <ResultsCard rent={rent} flat={selectedFlat} room={selectedRoom} flatRent={flatRent} />
          </SimpleGrid>
        </Stack>
      </Center>
    </ChakraProvider >
  );
}

export default App;
